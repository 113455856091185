'use client';

import { useGetPaymentMethod } from '@/api/payments/client';
import { SocialSignup } from '@/app/[lang]/(auth)/components/social-signup';
import { SignInDialog } from '@/app/[lang]/(auth)/components/sign-in-dialog';
import { getAuthErrorMessage } from '@/app/[lang]/(auth)/utils/auth-error';
import {
  getTeamsSeatValidationSchema,
  TeamsSeatInput,
  TeamsSeatInputFormValues
} from '@/app/[lang]/(shell)/settings/teams/components/buy-teams/teams-seat-input';
import { PaymentMethodSkeleton } from '@/components/payment-method/payment-method-skeleton';
import useStableCallback from '@/hooks/use-stable-callback';
import { AnalyticsProps } from '@/types';
import { Collapse, InputLabel } from '@mui/material';
import { useDynamicConfig } from '@statsig/react-bindings';
import { StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import dynamic from 'next/dynamic';
import { campaignStore } from '../store/campaign-store';
// @ts-ignore
import type { AuthError } from '@aws-amplify/auth/src/errors/AuthError';
import { PaymentMethodRef } from '@/components/payment-method/payment-method-update';
import { useRecaptcha } from '@/hooks/use-recaptcha';
import { useCurrentLocale } from '@/i18n';
import { useAuthStore } from '@/stores/auth-store-provider';
import { snackbarStore } from '@/stores/snackbar-store';
import { EMAIL_REGEX } from '@/utils/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Box from '@mui/material/Box';
import { useRouter } from '@/hooks/use-router';
import { useEffect, useMemo, useRef } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import * as yup from 'yup';

const InlinePaymentMethod = dynamic(
  () =>
    import('@/components/payment-method/inline-payment-method').then(
      (mod) => mod.InlinePaymentMethod
    ),
  {
    loading: () => <PaymentMethodSkeleton />
  }
);

export type CampaignFormInput = TeamsSeatInputFormValues & {
  email: string;
};

export type CampaignFormProps = AnalyticsProps & {
  formId: string;
  onSubmit?: (
    data: CampaignFormInput
  ) => void | boolean | Promise<boolean | void>;
  afterSubmit?: (
    stripePaymentMethodId: string,
    quantity: number
  ) => void | Promise<void>;
  skipPaymentMethod?: boolean;
  hideSocialSignup?: boolean;
  email?: string;
  priceId?: string | null;
  isTeam?: boolean;
};

function validationSchemaResolver(
  i18n: I18n,
  addSeatsValidationSchema?: boolean
) {
  let schema: yup.ObjectSchema<CampaignFormInput> = yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('validations.required'))
      .matches(EMAIL_REGEX, i18n.t('validations.invalid-email'))
  }) as any;
  if (addSeatsValidationSchema) {
    schema = schema.concat(getTeamsSeatValidationSchema(i18n));
  }
  return yupResolver(schema);
}

export function CampaignForm({
  formId,
  afterSubmit,
  skipPaymentMethod,
  hideSocialSignup,
  onSubmit,
  email,
  analyticsParentId,
  analyticsContext = {},
  priceId,
  isTeam
}: CampaignFormProps) {
  const { isLoading: isPaymentLoading } = useGetPaymentMethod();
  const shouldAutoSubmitRef = useRef(false);
  const authStore = useAuthStore();
  const popupState = usePopupState({
    variant: 'dialog'
  });
  const { recaptchaElement, isRecaptchaEnabled, executeRecaptcha } =
    useRecaptcha({ analyticsParentId });
  const locale = useCurrentLocale();
  const { i18n } = useLingui();
  const user = authStore.useTracked.user();
  const router = useRouter();
  const isLoading = campaignStore.useTracked.loading();
  const { value: dynamicConfigValue } = useDynamicConfig(
    'campaign_optimization_config'
  );

  const paymentMethodRef = useRef<PaymentMethodRef>(null);
  const form = useForm<CampaignFormInput>({
    defaultValues: { email: email || '' },
    resolver: validationSchemaResolver(i18n, isTeam && !skipPaymentMethod)
  });

  useEffect(() => {
    if (user?.email) {
      form.setValue('email', user.email);
    }
  }, [form, user?.email]);

  const handleSubmit = useStableCallback(
    async (
      data: CampaignFormInput,
      expressCheckout?: StripeExpressCheckoutElementConfirmEvent
    ) => {
      const shouldContinue = await onSubmit?.(data);

      if (shouldContinue === false) return;

      // Step 1: Set campaign loading to true
      campaignStore.set.loading(true);

      let paymentMethodId: string = '';

      // Step 2: Validate payment method if card doesn't exist
      if (!skipPaymentMethod && paymentMethodRef.current) {
        if (dynamicConfigValue.createPaymentMethodBeforeSignup) {
          const newStripePaymentMethodId =
            await paymentMethodRef.current.submit({
              skipUserCheck: !user,
              skipCardCheck: !!expressCheckout,
              skipAddUpdateInBackend: true,
              expressCheckout,
              email: data.email
            });

          if (newStripePaymentMethodId) {
            paymentMethodId = newStripePaymentMethodId;
          }
        } else {
          const isValid = paymentMethodRef.current.validate({
            skipUserCheck: !user,
            skipCardCheck: !!expressCheckout
          });
          if (!isValid) {
            campaignStore.set.loading(false);
            return;
          }
        }
      }

      rudderanalytics.track(
        'Click',
        {
          element_id: 'campaign-submit-button',
          type: 'BUTTON',
          parent_id: analyticsParentId,
          campaign_id: analyticsContext.campaign_id,
          email: analyticsContext.email,
          context: {
            ...analyticsContext,
            email: data.email,
            skip_payment_method: skipPaymentMethod,
            is_user_logged_in: !!user
          }
        },
        analyticsContext.campaign_data
      );

      // Step 3: If user is not logged in, create a new account
      if (!user) {
        try {
          await authStore.set.signUp(
            {
              email: data.email,
              locale,

              analyticsParentId,
              analyticsContext,
              analyticsElementId: 'campaign-submit-button',

              ...(!expressCheckout
                ? {
                    captcha: await executeRecaptcha(),
                    isRecaptchaEnabled
                  }
                : {})
            },
            true
          );

          router.refresh();
        } catch (error: AuthError) {
          // Step 4: If account exists, open up sign in form
          const accountExists =
            error.message.includes('UsernameExistsException') ||
            error.message.includes('ACCOUNT_EXISTS_ERROR') ||
            error.message.includes('User already exists');

          rudderanalytics.track(
            'Campaign Flow Error',
            {
              element_id: 'campaign-flow',
              type: 'FLOW',
              error: error.message,
              account_exists: accountExists,
              parent_id: analyticsParentId,
              offering_id: analyticsContext.offering_id,
              content_name: analyticsContext.offering_name,
              campaign_id: analyticsContext.campaign_id,
              email: analyticsContext.email,
              context: analyticsContext
            },
            analyticsContext.campaign_data
          );

          if (accountExists) {
            popupState.open();
          }

          if (!paymentMethodId && expressCheckout) {
            expressCheckout.paymentFailed({ reason: 'fail' });
          }

          const message = getAuthErrorMessage({
            error,
            i18n,
            defaultMessage: i18n.t('auth-errors.signup-error')
          });
          snackbarStore.set.create('error', message);
          campaignStore.set.loading(false);
          return;
        }
      }

      // Step 5: If card doesn't exist, create new payment method
      if (!skipPaymentMethod && paymentMethodRef.current && !paymentMethodId) {
        const isValid = paymentMethodRef.current.validate({
          skipCardCheck: !!expressCheckout
        });
        if (!isValid) {
          campaignStore.set.loading(false);
          return;
        }
        const newStripePaymentMethodId = await paymentMethodRef.current.submit({
          skipAddUpdateInBackend: true,
          skipCardCheck: !!expressCheckout,
          expressCheckout,
          email: data.email
        });

        if (newStripePaymentMethodId) {
          paymentMethodId = newStripePaymentMethodId;
        }
      }

      if (!skipPaymentMethod && !paymentMethodId) {
        campaignStore.set.loading(false);
        rudderanalytics.track(
          'Campaign Flow Error',
          {
            element_id: 'campaign-flow',
            type: 'FLOW',
            error: 'Payment method id not set',
            parent_id: analyticsParentId,
            offering_id: analyticsContext.offering_id,
            content_name: analyticsContext.offering_name,
            campaign_id: analyticsContext.campaign_id,
            email: analyticsContext.email,
            context: analyticsContext
          },
          analyticsContext.campaign_data
        );
        throw new Error('PAYMENT_METHOD_ERROR');
      }

      // Step 6: Proceed with campaign purchase flow
      await afterSubmit?.(paymentMethodId, data.seats || 1);
    }
  );

  const handleAfterSignIn = async () => {
    rudderanalytics.track(
      'Sign In Success',
      {
        element_id: 'campaign-form',
        type: 'FORM',
        parent_id: analyticsParentId,
        campaign_id: analyticsContext.campaign_id,
        email: analyticsContext.email,
        context: {
          ...analyticsContext,
          email: form.getValues().email
        }
      },
      analyticsContext.campaign_data
    );
    popupState.close();
    router.refresh();
    shouldAutoSubmitRef.current = true;
    campaignStore.set.loading(true);
  };

  const submitForm = useStableCallback(
    (event?: StripeExpressCheckoutElementConfirmEvent) =>
      form.handleSubmit((data) => handleSubmit(data, event))()
  );

  useEffect(() => {
    if (shouldAutoSubmitRef.current && !isPaymentLoading) {
      shouldAutoSubmitRef.current = false;
      void submitForm();
    }
  }, [submitForm, isPaymentLoading]);

  return (
    <>
      {popupState.isOpen && (
        <SignInDialog
          disableRedirect
          onAfterSubmit={handleAfterSignIn}
          defaultValues={{ email: form.getValues().email }}
          {...bindDialog(popupState)}
        />
      )}
      {!hideSocialSignup && !user && (
        <SocialSignup
          analyticsContext={analyticsContext}
          analyticsParentId={analyticsParentId}
          direction="column-reverse"
        />
      )}
      <FormContainer
        FormProps={{ id: formId }}
        formContext={form}
        onSuccess={(data) => handleSubmit(data)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 1.5, sm: 2 }
          }}
        >
          {priceId && isTeam && (
            <Collapse in={!skipPaymentMethod} mountOnEnter>
              <TeamsSeatInput readOnly={isLoading} priceId={priceId} />
            </Collapse>
          )}
          <TextFieldElement
            autoFocus={!email}
            id="email-input"
            data-testid="email-input"
            name="email"
            type="email"
            label={<Trans id="campaign-form.email-label">Email address</Trans>}
            placeholder={i18n.t({
              id: 'campaign-form.email-placeholder',
              message: 'Enter email address'
            })}
            margin="none"
            aria-label={i18n.t({
              id: 'campaign-form.email-placeholder',
              message: 'Enter email address'
            })}
            required
            autoComplete="email"
            onClick={() => {
              rudderanalytics.track('Click', {
                element_id: 'email-input',
                type: 'TEXT',
                parent_id: analyticsParentId,
                campaign_id: analyticsContext.campaign_id,
                context: analyticsContext
              });
            }}
            InputProps={{ readOnly: isLoading || !!user?.email }}
          />
          <Collapse in={!skipPaymentMethod} mountOnEnter>
            <InlinePaymentMethod
              label={
                <InputLabel required>
                  <Trans id="campaign-form.card-label">Card details</Trans>
                </InputLabel>
              }
              readOnly={isLoading}
              showOnlyCard
              enableExpressCheckout
              paymentMethodRef={paymentMethodRef}
              analyticsParentId={analyticsParentId}
              analyticsContext={analyticsContext}
              onExpressCheckout={submitForm}
            />
          </Collapse>
        </Box>
        {recaptchaElement}
      </FormContainer>
    </>
  );
}
