const OfferingFragment = /* GraphQL */ `
  fragment OfferingFragment on Offering {
    id
    heading
    type
    image {
      url
      width
      height
      cdnId
    }
    media {
      locator
      title
      assetType
    }
    description
    cta
    overrides {
      skipCreditCard
      trialDuration
      trialFrequency
    }
    products {
      items {
        product {
          id
          name
          description
          productNamespace
          entitlements {
            identifier
          }
          billingInterval
          priceId
          subscriptionGroup
        }
      }
    }
    productBundle {
      items {
        product {
          id
          name
          description
          productNamespace
          entitlements {
            identifier
          }
          billingInterval
          priceId
          subscriptionGroup
        }
        overrides {
          skipCreditCard
          trialDuration
          trialFrequency
        }
      }
    }
  }
`;

export const getOfferingStackGraphqlQuery = /* GraphQL */ `
  query GetOfferingStack($campaignId: ID!) {
    getCampaign(id: $campaignId) {
      offeringStack {
        id
        offering {
          redirectUrl
        }
        upsell {
          # Level 1 - Main Upsell
          offering {
            ...OfferingFragment
          }
        }
      }
    }
  }

  ${OfferingFragment}
`;

export const getOfferingStackUpsellPathGraphqlQuery = /* GraphQL */ `
  query GetOfferingStackUpsellPath($campaignId: ID!) {
    getCampaign(id: $campaignId) {
      offeringStack {
        upsell {
          # Level 1 - Yes Path
          upsellYes {
            offering {
              ...OfferingFragment
            }

            # Level 2 - Yes->Yes Path
            upsellYes {
              offering {
                ...OfferingFragment
              }

              # Level 3 - Yes->Yes->Yes Path
              upsellYes {
                offering {
                  ...OfferingFragment
                }
              }

              # Level 3 - Yes->Yes->No Path
              upsellNo {
                offering {
                  ...OfferingFragment
                }
              }
            }

            # Level 2 - Yes->No Path
            upsellNo {
              offering {
                ...OfferingFragment
              }

              # Level 3 - No->No->Yes Path
              upsellYes {
                offering {
                  ...OfferingFragment
                }
              }

              # Level 3 - No->No->No Path
              upsellNo {
                offering {
                  ...OfferingFragment
                }
              }
            }
          }

          # Level 1 - No Path
          upsellNo {
            offering {
              ...OfferingFragment
            }

            # Level 2 - No->Yes Path
            upsellYes {
              offering {
                ...OfferingFragment
              }

              # Level 3 - No->Yes->Yes Path
              upsellYes {
                offering {
                  ...OfferingFragment
                }
              }

              # Level 3 - No->Yes->No Path
              upsellNo {
                offering {
                  ...OfferingFragment
                }
              }
            }

            # Level 2 - No->No Path
            upsellNo {
              offering {
                ...OfferingFragment
              }

              # Level 3 - No->No->Yes Path
              upsellYes {
                offering {
                  ...OfferingFragment
                }
              }

              # Level 3 - No->No->No Path
              upsellNo {
                offering {
                  ...OfferingFragment
                }
              }
            }
          }
        }
      }
    }
  }

  ${OfferingFragment}
`;
