import { AlertProps, CheckboxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import { ReactNode } from 'react';
import { CheckboxElement, CheckboxElementProps } from 'react-hook-form-mui';

export type StyledCheckboxProps = CheckboxProps & {
  label?: ReactNode;
  color?: AlertProps['severity'];
};

export function StyledCheckbox({
  label,
  color = 'success',
  ...props
}: StyledCheckboxProps) {
  return (
    <Alert
      component="label"
      icon={<Checkbox sx={{ margin: -1 }} {...props} />}
      severity={color}
      sx={{
        fontSize: '1rem',
        cursor: 'pointer',
        borderWidth: 1,
        borderColor: `${color}.main`,
        borderStyle: 'solid',
        color: 'text.primary'
      }}
    >
      {label}
    </Alert>
  );
}

export type StyledCheckboxElementProps = CheckboxElementProps & {
  label?: ReactNode;
  color?: AlertProps['severity'];
};

export function StyledCheckboxElement({
  label,
  color = 'success',
  ...props
}: StyledCheckboxElementProps) {
  return (
    <Alert
      component="label"
      icon={
        <CheckboxElement sx={{ my: -1, ml: 0.4, mr: -3 }} {...(props as any)} />
      }
      severity={color}
      sx={{
        fontSize: '1rem',
        cursor: 'pointer',
        borderWidth: 1,
        borderColor: `${color}.main`,
        borderStyle: 'solid',
        color: 'text.primary'
      }}
    >
      {label}
    </Alert>
  );
}
