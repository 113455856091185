import { GraphQLResult } from '@aws-amplify/api-graphql';
import { produce } from 'immer';
import {
  getOfferingStackGraphqlQuery,
  getOfferingStackUpsellPathGraphqlQuery
} from './graphql';
import { GraphqlClient } from '@/libs/amplify/types';
import type { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  Campaign,
  OfferingStackUpsell,
  getCampaignSelectionSet,
  OfferingStack
} from './types';

export const getCampaignQueryKey = (id: string) => {
  return ['GetCampaign', { id }] as const;
};

export type GetCampaignQueryKey = ReturnType<typeof getCampaignQueryKey>;

export function getCampaignQuery(
  graphqlClient: GraphqlClient,
  id: string
): UseSuspenseQueryOptions<
  Campaign | null,
  DefaultError,
  Campaign | null,
  GetCampaignQueryKey
> {
  return {
    queryKey: getCampaignQueryKey(id),
    queryFn: async () => {
      if (!id) {
        return null;
      }

      const { data, errors } = await graphqlClient.models.Campaign.get(
        { id },
        {
          selectionSet: getCampaignSelectionSet
        }
      );

      if (errors?.length) {
        console.error(
          'Error fetching campaign: ',
          errors.map((e) => e.message).join(', ')
        );
      }

      return (data as unknown as Campaign) || null;
    }
  };
}

export const getOfferingStackQueryKey = (campaignId: string) => {
  return ['GetOfferingStack', { campaignId }] as const;
};

export type GetOfferingStackQueryKey = ReturnType<
  typeof getOfferingStackQueryKey
>;

function mapUpsellItems(upsell: any) {
  if (upsell?.offering?.products?.items) {
    upsell.offering.products = upsell.offering.products.items;
  }
  if (upsell?.offering?.productBundle?.items) {
    upsell.offering.productBundle = upsell.offering.productBundle.items;
  }

  if (upsell?.upsellYes) {
    mapUpsellItems(upsell.upsellYes);
  }
  if (upsell?.upsellNo) {
    mapUpsellItems(upsell.upsellNo);
  }
}

export function getOfferingStackQuery(
  graphqlClient: GraphqlClient,
  campaignId: string
): UseSuspenseQueryOptions<
  OfferingStack | null,
  DefaultError,
  OfferingStack | null,
  GetOfferingStackQueryKey
> {
  return {
    queryKey: getOfferingStackQueryKey(campaignId),
    queryFn: async () => {
      if (!campaignId) {
        return null;
      }

      const { data, errors } = (await graphqlClient.graphql({
        query: getOfferingStackGraphqlQuery,
        variables: { campaignId }
      })) as GraphQLResult<{
        getCampaign: { offeringStack: OfferingStack };
      }>;

      if (errors?.length) {
        console.error(
          'Error fetching offering stack: ',
          errors.map((e) => e.message).join(', ')
        );
      }

      const offeringStack = data?.getCampaign?.offeringStack;

      if (offeringStack) {
        produce(offeringStack, (draft) => {
          mapUpsellItems(draft.upsell);
        });
      }

      return null;
    }
  };
}

export const getOfferingStackUpsellPathQueryKey = (campaignId: string) => {
  return ['GetOfferingStackUpsellPath', { campaignId }] as const;
};

export type GetOfferingStackUpsellPathQueryKey = ReturnType<
  typeof getOfferingStackUpsellPathQueryKey
>;

export function getOfferingStackUpsellPathQuery(
  graphqlClient: GraphqlClient,
  campaignId: string
): UseSuspenseQueryOptions<
  OfferingStackUpsell | null,
  DefaultError,
  OfferingStackUpsell | null,
  GetOfferingStackUpsellPathQueryKey
> {
  return {
    queryKey: getOfferingStackUpsellPathQueryKey(campaignId),
    queryFn: async () => {
      if (!campaignId) {
        return null;
      }

      const { data, errors } = (await graphqlClient.graphql({
        query: getOfferingStackUpsellPathGraphqlQuery,
        variables: { campaignId }
      })) as GraphQLResult<{
        getCampaign: { offeringStack: OfferingStackUpsell };
      }>;

      if (errors?.length) {
        console.error(
          'Error fetching offering stack upsell: ',
          errors.map((e) => e.message).join(', ')
        );
      }

      const offeringStack = data?.getCampaign?.offeringStack;

      if (offeringStack) {
        produce(offeringStack, (draft) => {
          mapUpsellItems(draft.upsell);
        });
      }

      return null;
    }
  };
}
