import { AlertProps, Box, RadioProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import { ReactNode } from 'react';
import { RadioButtonGroup, RadioButtonGroupProps } from 'react-hook-form-mui';

export type StyledRadioProps = RadioProps & {
  label?: ReactNode;
  color?: AlertProps['severity'];
};

export function StyledRadio({
  label,
  color = 'success',
  ...props
}: StyledRadioProps) {
  return (
    <Alert
      component="label"
      icon={<Radio sx={{ margin: -1 }} {...props} />}
      severity={color}
      sx={{
        fontSize: '1rem',
        cursor: 'pointer',
        borderWidth: 1,
        borderColor: `${color}.main`,
        borderStyle: 'solid',
        color: 'text.primary'
      }}
    >
      {label}
    </Alert>
  );
}

export type StyledRadioGroupProps = RadioButtonGroupProps & {
  label?: ReactNode;
  color?: AlertProps['severity'];
};

export function StyledRadioGroup({
  label,
  color = 'success',
  ...props
}: StyledRadioGroupProps) {
  return (
    <Box
      sx={{
        '& > .MuiFormControl-root': {
          width: '100%'
        }
      }}
    >
      <RadioButtonGroup
        {...props}
        labelProps={{
          ...props.labelProps,
          sx: [
            {
              borderColor: 'var(--mui-palette-success-main)',
              borderWidth: 1,
              borderStyle: 'solid',
              backgroundColor: 'var(--mui-palette-Alert-successStandardBg)',
              borderRadius: 1,
              padding: '4px 16px 4px 4px',
              width: '100%',
              margin: 0,
              '&:not(:last-child)': {
                marginBottom: 1
              }
            },
            ...(Array.isArray(props.labelProps?.sx)
              ? props.labelProps?.sx
              : [props.labelProps?.sx])
          ]
        }}
      />
    </Box>
  );
}
